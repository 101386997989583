
import React from 'react';
import './App.css';
import './CompanyCustom.css'
import './font.css';
import { Routes, Route } from 'react-router-dom';
// import { I18nextProvider } from 'react-i18next';
// import i18n from './Pages/i18n';
import MaintainenceScreen from './Components/MaintainenceScreen';
import NotFoundPage from './Components/Error/NotFoundPage';
import Screensaver from './Pages/Screensaver'

const App = () => {

  return (
    <div className='homePage'>
      {/* <I18nextProvider i18n={i18n}> */}
            <Routes>
              <Route path="/" exact element={<Screensaver /> } />
              <Route path="/maintenance" element={<MaintainenceScreen />} />
              <Route path="/error" element={<NotFoundPage />} />
            </Routes>
      {/* </I18nextProvider> */}
    </div>
  );
};

export default App;
